<template>
  <div
    class="md:fixed scroll-y-overflow top-0 left-0 w-full h-full z-ultimateTop bg-background"
    :class="{ 'h-screen': !isEditMode, 'h-full': isEditMode }"
  >
    <div class="md:flex md:flex-row h-screen w-screen">
      <div class="relative md:w-1/2 flex items-center justify-center">
        <nuxt-img
          v-if="imageUrl"
          preload
          preset="standard"
          sizes="100vw sm:50vw md:75vw"
          class="w-[768px] h-[340px] md:w-[1020px] md:h-[1150px] object-contain select-none p-24 md:px-80"
          :src="getImagePrefix(imageUrl)"
          alt="Age gate"
        />
      </div>
      <div
        class="md:w-1/2 bg-background age-gate-content-container p-24 md:px-80 text-center flex flex-col items-center md:justify-center overflow-y-auto"
      >
        <div class="age-gate-content max-w-380">
          <img
            src="/icons/zyn-new-logo-blue.svg"
            class="h-24 object-contain select-none mx-auto mb-32 mt-24 md:mt-0 block"
            height="25"
            width="120"
            alt="Zyn logo"
          >
          <p class="type-headline-base mt-16">
            {{ $lang("ageGateResources", "ageGateHeader") }}
          </p>
          <p class="type-sm font-semibold mt-12">
            {{ $lang("ageGateResources", "enterDateOfBirth") }}
          </p>
          <div class="grid grid-cols-2 gap-16">
            <div
              v-if="months.length > 1"
              class="mb-16 relative w-full max-w-410 mt-20"
            >
              <div
                class="px-16 py-20 border border-grey600 flex justify-between items-center select-none cursor-pointer"
                :class="{
                  'rounded-b-none': monthSelectorOpen,
                }"
                @click="openMonthSelector"
              >
                <div
                  v-if="selectedMonth"
                  class="flex items-center type-xs capitalize"
                >
                  {{ selectedMonth }}
                </div>
                <div v-else class="flex items-center text-neutral-dark type-xs">
                  {{ $lang("ageGateResources", "month") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-12 h-12"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </div>
              <ClientOnly>
                <transition name="month-appear" mode="out-in">
                  <div
                    v-show="monthSelectorOpen"
                    class="absolute right-0 left-0 max-h-240 md:max-h-360 bg-white z-10 type-xs border-b border-r border-l border-grey600 overflow-auto cursor-pointer select-none"
                  >
                    <a
                      v-for="month in months"
                      :key="month"
                      href="#month"
                      class="flex p-16 py-8 cursor-pointer select-none type-xs capitalize"
                      @click.prevent="onMonthSelect(month)"
                    >
                      {{ month }}
                    </a>
                  </div>
                </transition>
              </ClientOnly>
            </div>
            <div
              v-if="years.length > 1"
              class="mb-16 relative w-full max-w-410 mt-20"
            >
              <div
                class="px-16 py-20 border border-grey600 flex justify-between items-center select-none cursor-pointer"
                :class="{
                  'rounded-b-none': yearSelectorOpen,
                }"
                @click="openYearSelector"
              >
                <div
                  v-if="selectedYear"
                  class="flex items-center type-xs capitalize"
                >
                  {{ selectedYear }}
                </div>
                <div v-else class="flex items-center text-neutral-dark type-xs">
                  {{ $lang("ageGateResources", "year") }}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-12 h-12"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </div>
              <ClientOnly>
                <transition name="year-appear" mode="out-in">
                  <div
                    v-show="yearSelectorOpen"
                    class="absolute right-0 left-0 max-h-240 md:max-h-360 bg-white z-10 border-b border-r border-l border-grey600 type-xs overflow-auto cursor-pointer select-none"
                  >
                    <a
                      v-for="year in years"
                      :key="year"
                      href="#year"
                      class="flex p-16 py-8 cursor-pointer select-none"
                      @click.prevent="onYearSelect(year)"
                    >
                      {{ year }}
                    </a>
                  </div>
                </transition>
              </ClientOnly>
            </div>
          </div>
          <div
            v-if="config.markets.length > 1 && isStartPage"
            class="mb-24 relative w-full max-w-410 mt-0"
          >
            <div
              class="px-16 py-20 border border-grey600 type-xs flex justify-between items-center select-none cursor-pointer"
              :class="{
                'rounded-b-none': marketSelectorOpen,
              }"
              @click="openMarketSelector"
            >
              <div v-if="selectedMarket" class="flex items-center type-xs">
                <img
                  v-if="selectedMarket.countryCode === 'SE'"
                  src="~/assets/icons/flag-defs-se.svg"
                  class="h-20 w-20 mr-8 object-contain"
                  alt="flag-lang"
                >
                <img
                  v-else-if="selectedMarket.countryCode === 'DK'"
                  src="~/assets/icons/flag-defs-dk.svg"
                  class="h-20 w-20 mr-8 object-contain"
                  alt="flag-lang"
                >
                <img
                  v-else-if="selectedMarket.countryCode === 'AT'"
                  src="~/assets/icons/flag-defs-at.svg"
                  class="h-20 w-20 mr-8 object-contain"
                  alt="flag-lang"
                >
                <img
                  v-else-if="selectedMarket.countryCode === 'SK'"
                  src="~/assets/icons/flag-defs-sk.svg"
                  class="h-20 w-20 mr-8 object-contain"
                  alt="flag-lang"
                >
                <img
                  v-else
                  src="~/assets/icons/flag-defs-global.svg"
                  class="h-20 w-20 mr-8 object-contain"
                  alt="flag-lang"
                >
                <template v-if="selectedMarket.enablePurchases">
                  {{ selectedMarket.ageGateText }} ({{
                    selectedMarket.currencyCode
                  }})
                </template>
                <template v-else>
                  {{ selectedMarket.ageGateText }}
                </template>
              </div>
              <div v-else class="flex items-center text-neutral-dark type-xs">
                {{ $lang("ageGateResources", "chooseMarket") }}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12"
                viewBox="0 0 512 512"
              >
                <path
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                />
              </svg>
            </div>
            <transition name="market-appear" mode="out-in">
              <div
                v-show="marketSelectorOpen"
                class="absolute right-0 left-0 md:max-h-160 max-h-460 bg-white z-10 type-xs border-b border-r border-l border-grey600 overflow-auto cursor-pointer select-none"
              >
                <a
                  v-for="market in config.markets"
                  :key="market.countryId"
                  :href="market.url"
                  class="flex p-16 cursor-pointer select-none"
                  @click.prevent="onMarketSelect(market)"
                >
                  <img
                    v-if="market.countryCode === 'SE'"
                    src="~/assets/icons/flag-defs-se.svg"
                    class="h-20 w-20 mr-8 object-contain"
                    alt="flag-lang"
                  >
                  <img
                    v-else-if="market.countryCode === 'DK'"
                    src="~/assets/icons/flag-defs-dk.svg"
                    class="h-20 w-20 mr-8 object-contain"
                    alt="flag-lang"
                  >
                  <img
                    v-else-if="market.countryCode === 'AT'"
                    src="~/assets/icons/flag-defs-at.svg"
                    class="h-20 w-20 mr-8 object-contain"
                    alt="flag-lang"
                  >
                  <img
                    v-else-if="market.countryCode === 'SK'"
                    src="~/assets/icons/flag-defs-sk.svg"
                    class="h-20 w-20 mr-8 object-contain"
                    alt="flag-lang"
                  >
                  <img
                    v-else
                    src="~/assets/icons/flag-defs-global.svg"
                    class="h-20 w-20 mr-8 object-contain"
                    alt="flag-lang"
                  >
                  <template v-if="market.enablePurchases">
                    {{ market.ageGateText }} ({{ market.currencyCode }})
                  </template>
                  <template v-else>
                    {{ market.ageGateText }}
                  </template>
                </a>
              </div>
            </transition>
          </div>
          <Button
            :disabled="
              !selectedMarket ||
                selectedMonth == '' ||
                selectedYear === 0 ||
                !approvedAge
            "
            :size="Sizes.lg"
            :theme="Themes.primary"
            class="btn w-full mb-20 mt-24"
            :text="$lang('ageGateResources', 'buttonText')"
            :is-block="true"
            @click="onMarketApply"
          />

          <a
            class="type-xs text-neutral-dark hover:underline"
            :href="cancelUrl"
          >
            {{ $lang("ageGateResources", "declineText") }}
          </a>

          <p class="type-xxs mt-24">
            {{ $lang("ageGateResources", "ageGateInfo") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Sizes, Themes } from '../constants/button-layouts';
import Button from './globals/Button.vue';
import { type IMarket } from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import { useGlobalContentStore } from '~/store/globalContent';

const { getImagePrefix } = useImages();
const globalContentStore = useGlobalContentStore();
const pageStore = usePageContentStore();
const config = useGlobalContentStore().config;
const { $lang } = useNuxtApp();

const props = defineProps<{
  cancelUrl: string;
  imageUrl: string;
  isEditMode?: boolean;
}>();

const selectedMarket = ref(config?.currentMarket);
const selectedMonth = ref('');
const selectedYear = ref(0);
const marketSelectorOpen = ref(false);
const monthSelectorOpen = ref(false);
const yearSelectorOpen = ref(false);
const dayjs = useDayjs();

const months = computed(() => {
  return dayjs.months();
});

const years = computed(() => {
  let year = new Date().getFullYear();
  let range = 101;
  return Array(range)
    .fill(range)
    .map((_, idx) => year - idx);
});

const isStartPage = computed(() => {
  return globalContentStore.pageType === 'StartPage';
});

onBeforeMount(() => {
  if (config.markets.length === 1) {
    selectedMarket.value = config.markets[0];
  }
});

onMounted(() => {
  document.body.classList.add('md:overflow-hidden');
});

onUnmounted(() => {
  document.body.classList.remove('md:overflow-hidden');
});

const openMarketSelector = () => {
  marketSelectorOpen.value = !marketSelectorOpen.value;
  monthSelectorOpen.value = false;
  yearSelectorOpen.value = false;
};

const openMonthSelector = () => {
  marketSelectorOpen.value = false;
  monthSelectorOpen.value = !monthSelectorOpen.value;
  yearSelectorOpen.value = false;
};

const openYearSelector = () => {
  marketSelectorOpen.value = false;
  monthSelectorOpen.value = false;
  yearSelectorOpen.value = !yearSelectorOpen.value;
};

const onMarketSelect = (market: IMarket) => {
  selectedMarket.value = market;
  marketSelectorOpen.value = false;
};

const onMonthSelect = (month: string) => {
  selectedMonth.value = month;
  monthSelectorOpen.value = false;
};

const onYearSelect = (year: number) => {
  selectedYear.value = year;
  yearSelectorOpen.value = false;
};

const approvedAge = computed(() => {
  const minAge = 18;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  if (selectedYear.value > 0) {
    const approvedYear = year - minAge;

    if (selectedYear.value < approvedYear) {
      return true;
    } else if (selectedYear.value === approvedYear) {
      const months = dayjs.months();
      const selectedMonthIndex = months?.indexOf(selectedMonth.value);
      return selectedMonthIndex <= month;
    }
  }

  return false;
});

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const onMarketApply = () => {
  const ageGateCookie = useCookie('age_gate_market', {
    path: '/',
    maxAge: 60 * 60 * 24 * 180,
  });

  ageGateCookie.value = selectedMarket.value?.url || '/';

  if (
    (config.theme === 'niqo' || selectedMarket.value?.url === config.currentMarket?.url) &&
    !isStartPage.value
  ) {
    emit('close');
  } else {
    window.location.href = selectedMarket.value?.url || '/';
  }
};

const host = globalContentStore.host;

if (host && props.imageUrl) {
  useHead({
    link: [
      {
        rel: 'preload',
        href: host + props.imageUrl,
        as: 'image',
      },
    ],
  });
}
</script>
<style scoped>
.age-gate-bg {
  padding-top: 50%;
  @apply overflow-hidden md:p-0 md:h-screen;
}
.age-gate-content-container {
  @apply md:h-screen;
}
@media (min-width: 768) {
  .age-gate-content-container {
    height: 75%;
  }
}
@media (max-height: 600px) {
  .age-gate-content-container {
    @apply block;
  }

  .age-gate-content {
    @apply mx-auto md:mt-48;
  }
}

.scroll-y-overflow {
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
